
import { defineComponent, reactive, ref } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import { DataItem } from '@/types/app.global';
import { SimpleTableExposeType } from '@/types/types.config';
import constants from '@/utils/constants';

import { SafetyOutlined, PlusOutlined } from '@ant-design/icons-vue';
import RestTable from '@/components/table/RestTable.vue';
import FormModal from '@/components/composite/FormModal.vue';
import ModalWidget from '@/components/widget/ModalWidget.vue';
import SelectWidget from '@/components/form/SelectWidget.vue';

export default defineComponent({
  name: 'Staff',
  components: { SelectWidget, ModalWidget, FormModal, RestTable, SafetyOutlined, PlusOutlined },
  setup() {
    const { https, mapper, message, notify } = useInject();
    const { userMeta } = useGetters();
    const profile = process.env.VUE_APP_PROFILE;
    const counter = ref(0); // 验证码发送倒计时

    // 1. 表格
    const tableRef = ref<SimpleTableExposeType>();
    const tableOpts = {
      selection: {
        type: 'radio'
      },
      search: {
        criteria: {
          cols: [
            {
              field: 'alias',
              label: '用户名'
            }
          ]
        },
        remote: {
          params: { deptId: userMeta.deptId },
          action: https?.sys.pageUser
        }
      },
      cols: [
        {
          field: 'uid',
          label: 'UID'
        },
        {
          field: 'alias',
          label: '用户名'
        },
        {
          field: 'name',
          label: '姓名'
        },
        {
          field: 'phone',
          label: '手机号'
        },
        {
          field: 'deptName',
          label: '部门'
        },
        {
          field: 'roleName',
          label: '职位'
        },
        {
          field: 'status',
          label: '状态',
          options: mapper?.common.enableStr
        }
      ],
      schema: {
        content: {
          cols: [
            {
              field: 'alias',
              label: '用户名',
              disabled: true
            },
            {
              field: 'name',
              label: '姓名',
              disabled: true
            },
            {
              field: 'phone',
              label: '手机号',
              disabled: true
            },
            {
              field: 'roleName',
              label: '职位',
              type: 'select',
              mapper: ['id', 'name'],
              remote: {
                params: { type: constants?.enums.roleType.STAFF, deptId: userMeta.deptId, roleId: userMeta.roleId },
                action: https?.sys.listRole
              },
              fetch: true,
              required: true,
              evaluate: (data: DataItem) => ({ roleId: data.id, roleName: data.name, deptId: data.deptId, deptName: data.deptName })
            },
            {
              field: 'status',
              label: '状态',
              type: 'switch',
              options: mapper?.common.enableStr
            }
          ]
        }
      },
      builtinCmd: {
        update: { action: https?.sys.updateUser }
      }
    };

    // 2. 注册
    const regStaff = () => {
      const dept = regEntity.roleName;
      const params = { ...regEntity, ...dept, oid: userMeta.uid, pid: userMeta.uid, token: userMeta.token };
      https?.sys.createUser(params).then(res => {
        if (res.respType === 'OK') {
          regOpts.visible = false;
          notify?.success('注册成功');
        } else {
          notify?.error('注册失败：' + res.respMesg);
        }
      }, () => { regOpts.visible = false; });
    };

    const regOpts = reactive({
      title: '注册员工',
      visible: false,
      action: regStaff
    });

    const regEntity = reactive({
      alias: '',
      phone: '',
      pwd: '',
      oidSms: '',
      oidPwd: '',
      roleName: '' as string | any
    });

    const smsOpts = reactive({
      visible: true,
      disabled: false
    });

    const roleOpts = {
      field: 'roleName',
      label: '职位',
      type: 'select',
      mapper: ['id', 'name'],
      remote: {
        params: { type: constants?.enums.roleType.STAFF, deptId: userMeta.deptId, roleId: userMeta.roleId },
        action: https?.sys.listRole
      },
      required: true,
      evaluate: (data: DataItem) => ({ roleId: data.id, roleName: data.name, deptId: data.deptId, deptName: data.deptName })
    };

    // 开发环境使用模拟的短信验证码
    if (profile === 'dev' || profile === 'sit') {
      regEntity.oidSms = '123456';
      smsOpts.visible = false;
    }

    const countdown = () => {
      counter.value = 60;
      const t = setInterval(() => {
        counter.value -= 1;
        if (counter.value === 0) {
          clearInterval(t);
        }
      }, 1000);
    };

    const sendSms = () => {
      smsOpts.disabled = true;
      https?.member.sendRegSmsCode({ oid: userMeta.uid, token: userMeta.token }).then(res => {
        smsOpts.disabled = false;
        if (res.respType === 'OK') {
          countdown();
          message?.success('验证码已发送');
        } else {
          message?.warning('验证码发送失败: ' + res.respMesg);
        }
      }, () => { smsOpts.disabled = false; });
    };

    // 3. 实名
    const realOpts = reactive({
      title: '用户实名',
      visible: false,
      action: https?.sys.userReal,
      content: {
        entity: {},
        cols: [
          {
            field: 'uid',
            label: '用户ID',
            disabled: true
          },
          {
            field: 'idNo',
            label: '身份证号',
            required: true
          },
          {
            field: 'idName',
            label: '姓名',
            required: true
          }
        ]
      }
    });

    const showReg = () => {
      // 打开弹出框重置regEntity数据
      regEntity.alias = '';
      regEntity.phone = '';
      regEntity.pwd = '';
      regEntity.oidSms = '';
      if (profile === 'dev' || profile === 'sit') {
        regEntity.oidSms = '123456';
      }

      smsOpts.disabled = false;
      regOpts.visible = true;
    };

    const showReal = () => {
      const row = tableRef.value?.expose.getSelectedData()[0];
      if (!row) {
        message?.warn('请选择一条数据！');
        return;
      }

      if (row.name !== '未实名') {
        message?.warn('已实名，请勿重复操作！');
        return;
      }

      realOpts.content.entity = { uid: row.uid, idNo: '', idName: '' };
      realOpts.visible = true;
    };
    return { tableRef, tableOpts, regOpts, regEntity, roleOpts, smsOpts, realOpts, smsPhone: userMeta.phone, counter, sendSms, showReg, showReal };
  }
});
